import React from 'react';

import logo from '../../logo-2.png';
import './header.css';

import Link from '../Link'

const Header = () => {
    return (
        <header className="Masthead">
            <a href="/"><img src={logo} className="App-logo" alt="logo"/></a>
            <nav>
            <ul>
                <li><Link href="/">Home</Link></li>
                <li><Link href="/about">About Me</Link></li>
                <li><Link href="/resume">Resumé</Link></li>
                <li><a href="https://github.com/k-katfish">GitHub</a></li>
            </ul>
            </nav>
        </header>
    )
}

export default Header