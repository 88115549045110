import React from 'react';
import './windows.css'
import WindowsNav from './WindowsNav';

const Windows = () => {
    return (
        <div className='Windows-page'>
            <WindowsNav />

            <div className='Windows-Content'>

                <h1>Windows System Administration</h1>

                <h2>Check out some of my big topics using the secondary navigation bar.</h2>

                <h2>I have experience with:</h2>

                <div className="Content">
                    <ul>
                        <li>Creating, documenting, and maintaining Group Policy objects</li>
                        <li>Windows Server Operating Systems</li>
                        <li>Windows for Enterprise, including Windows 10 LTSB/LTSC for Enterprise</li>
                        <li>Microsoft Active Directory in a Windows Server domain</li>
                        <li>Creating, Managing, and Deploying custom Windows images with Microsoft Deployment Toolkit and SCCM</li>
                        <li>Administering an MDT server</li>
                        <li>Creating silent/automated installers for use with Microsoft Deployment Toolkit/SCCM</li>
                        <li>Automating regular or large tasks with PowerShell and .bat scripts</li>
                        <li>Configuring permissions, users, groups, and more on a Windows Server domain</li>
                        <li>Accidentally writing a group policy object that caused problems for users (oops)</li>
                        <li>Building Hyper-V Virtual Machines</li>
                        <li>Deploying to Virtual Hard Disks with MDT</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Windows