import './style.css';
import Accordion from "../../../components/Accordion";
import FixUD from "./Resources/FixUEFIDetection.wsf";
import FixUDPng from "./Resources/fixuefid.png";

const FUD = () => {
    return (
        <div className="Tip-division">
            <Accordion
                title={<h2>Fix UEFI Detection</h2>}
                content={
                    <p>
                        So you ran into a problem with MDT on an older computer with both BIOS boot and UEFI boot enabled. 
                        It fails to finish, and says something about <strong>Verify BCDBootEx</strong> in the error message.
                        <h3>The solution</h3>
                        Download the <a href={FixUD} download="FixUEFIDetection.wsf">FixUEFIDetection.wsf</a> script 
                        (note- you can also find this on the <a href="https://github.com/DeploymentResearch/DRFiles/blob/master/Scripts/FixUEFIDetection.wsf">DeploymentResearch GitHub</a>).
                        Once you have it downloaded, copy it to your scripts folder on your MDT share (MDTShare$\Scripts).
                        Now, add a step to your task sequence(s):
                        Create a new step, a "Run Command Line" type. Call it something descriptive, like Fix UEFI Detection.
                        Here's the command you need to run: cscript.exe "%SCRIPTROOT%\FixUEFIDetection.wsf"
                        <br/>
                        <img src={FixUDPng} alt='An MDT Task Sequence, detail of the Fix UEFI Detection step'/>
                        <br/>
                        Add the step during the Preinstall phase, before the disk gets formatted (in the default task sequences this is under the "New Computer Only" group, I put the script step right before that "New Computer Only" group).
                        <br/><br/>
                        Your mileage may vary. For further resources, check out these resources:
                        <ul>
                            <li>Deployment Research: <a href="https://www.deploymentresearch.com/making-mdt-work-with-windows-adk-2004-for-bios-machines/">Making MDT work with Windows ADK 2004 for BIOS machines</a></li>
                            <li>r/MDT: <a href='https://www.reddit.com/r/MDT/comments/qzk1wh/mdt_failure_5616_15250_verify_bcdbootex/'>post by skipITjob </a></li>
                        </ul>

                    </p>
                }
                color={'#fff'}
                bodycolor={'#fff'}
            />
        </div>
    )
}

export default FUD;