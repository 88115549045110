import './index.css';
import Headshot from './about/KyleKetchellHeadshot.JPEG';


const Home = () => {
    return (
        <div className='Home-page'>
            <header>
                <h1>Kyle Ketchell</h1>
            </header>

            <div className='Welcome'>
                <p className='Welcome-text'>Welcome! My name is Kyle, I'm a System Administrator and all-around computer nerd. You can find some blog-type content down the left side of the page (or in the lower navigation bar if you're viewing this on a phone) and my resume <a href="/resume">here</a>.</p>
                <img src={Headshot} alt="Kyle Ketchell" className='Headshot'/>
            </div>

            <h1>Need IT help?</h1>
            <h2>Email <a href="mailto:help@kkatfish.com">help@kkatfish.com</a> and I'll get back to you as soon as I can.</h2>
        </div>
    )
}

export default Home

/* linked in badge - broken
<script src="https://platform.linkedin.com/badges/js/profile.js" async defer type="text/javascript"></script>
<div class="badge-base LI-profile-badge" data-locale="en_US" data-size="large" data-theme="dark" data-type="HORIZONTAL" data-vanity="kyle-ketchell-914495208" data-version="v1"><a class="badge-base__link LI-simple-link" href="https://www.linkedin.com/in/kyle-ketchell-914495208?trk=profile-badge">Kyle Ketchell</a></div>
*/