import React from 'react';

const Link = ({ className, href, children }) => {
    const onClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            return;
        }

        event.preventDefault();         // prevent full page reload
        window.history.pushState({}, "", href)  // update url

        // tell routes that the url has changed
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
    }

    return (
        <a className={className} href={href} onClick={onClick}>{children}</a>
    )
}

export default Link;

// Dude, Nick Coughlin... where would I be without you?
// https://ncoughlin.com/posts/react-navigation-without-react-router/