import './resume-style.css';
import github from './github-mark.svg';
import protonmail from './pm.svg';
import linkedin from './linkedin.png';
import Accordion from '../../components/Accordion';
import csu from './CSU-Ram-357-617.png';
import eccouncil from './eccouncil.png';
import comptia from './SecurityPlus Certified CE.png';
import printicon from './printer.svg';
import resume_print from './resume.docx';

import "@fontsource/roboto";

//                <h3 className="Header-sub-subtitle">System Administrator</h3>

const Resume = () => {
    return (
        <div className="Resume-page">
            <div>
                <h1 className="Header-title">Kyle Ketchell</h1>
                <h2 className="Header-subtitle">System Administrator | Fort Collins, Colorado</h2>

                <div className='Contact-items'>
                    <a className='Contact-item' href="https://github.com/k-katfish">
                        <img src={github} alt="Github"></img>
                        <h3>github.com/k-katfish</h3>
                    </a>

                    <div className='Contact-item'><p>        </p></div>

                    <a className='Contact-item' href="mailto:Kyle.Ketchell@pm.me">
                        <img src={protonmail} alt="Proton Mail"></img>
                        <h3>Kyle.Ketchell@pm.me</h3>
                    </a>

                    <div className='Contact-item'><p>        </p></div>

                    <a className='Contact-item' href="https://www.linkedin.com/in/kyle-ketchell-914495208">
                        <img src={linkedin} alt="Linked in" className='linkedin-special'></img>
                    </a>

                    <div className='Contact-item'><p>        </p></div>

                    <a className='Contact-item' href={resume_print} download="Kyle_Ketchell_Resume.docx">
                        <img src={printicon} alt="Print this page"></img>
                        <h3>Download print version</h3>
                    </a>
                </div>
            </div>

            <div className='Resume-Summary'>
                <p>
                    Interested in a challenging System Administrator position in a dynamic organization 
                    where my strong technical and analytical skills can be utilized to support business goals.
                </p>

                <p>
                    Highly motivated and experienced System Administrator with 7 years of experience managing 
                    Linux and Windows systems. Proficient in several Linux distributions, including CentOS, Red Hat 
                    Enterprise Linux, Debian, Fedora, (and more). Proficient in both Windows 10/11 and Windows Server
                    2016, 2019, and 2022. Skilled in installation, configuration, and 
                    troubleshooting of servers, network devices, and applications. Strong expertise in 
                    scripting and administration with Bash and Powershell. Excellent communication skills and ability
                    to work independently or collaboratively in a team environment.
                </p>
            </div>

            <div className='Resume-Content'>
                <div className='Resume-Content-column-left'>
                    <div className='Resume-Section'>
                        <div className='Experience'>
                            <div className='Experience-left'>
                                <div className='Resume-Section'>
                                    <h2>Professional Experience</h2>

                                    <div className='Professional-Experience'>
                                        <div className='Generic-Card-Orange'>
                                            <Accordion
                                                title={<h3>Seismo-Acoustic Network Security Technician, Wilson Alaska Technical Center/UAF, 2023 - Present</h3>}
                                                content={
                                                    <ul>
                                                        <li>Responsible for maintaining 99.99999% uptime (7 Nines, 4 seconds of downtime) for main backbone network infrastructure</li>
                                                        <li>Implemented secure network protocols for system infrastructure</li>
                                                        <li>Planned, tested, and orchestrated week-long infrastructure migration with just 2 seconds of downtime</li>
                                                        <li>Instructed team members and industry partners in cybersecurity best practices</li>
                                                        <li>Built and maintained supporting network infrastructure servers</li>
                                                        <li>Programmed scripts to automate all the things</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Generic-Card-Orange'>
                                            <Accordion
                                                title={<h3>System Administrator, Engineering Technology Services, 2022 - 2023</h3>}
                                                content={
                                                    <ul>
                                                        <li>Initiated several massive overhauls to system infrastructure to increase security compliance and save over $10,000</li>
                                                        <li>Streamlined lab deployment workflow for a remarkable increase in time efficiency in deployments (over 5000%, 10-12 hour installations now take under 2 hours)</li>
                                                        <li>Strategically redesigned Group Policy objects to dramatically decrease processing time and reduce network bandwidth</li>
                                                        <li>Administered Linux systems to host time-saving services including dashboards and a new inventory system</li>
                                                        <li>Developed and implemented backup and recovery plans for critical systems and applications</li>
                                                        <li>Lead a team of system administratos to deploy lab spaces across several facilities</li>
                                                        <li>Monitored and analyzed server performance and capacity using Prometheus and Grafana</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Generic-Card-Orange'>
                                            <Accordion
                                                title={<h3>Support Technician, Engineering Technology Services, 2020 - 2023</h3>}
                                                content={
                                                    <ul>
                                                        <li>Diagnosed and repaired advanced software and hardware issues to deliver a hassle free computing environment to clients</li>
                                                        <li>Engaged with clients to provided innovative solutions to new problems</li>
                                                        <li>Established and documented disaster procedures and emergency processes</li>
                                                        <li>Troubleshooted system issues and provided prompt resolution to minimize downtime</li>
                                                        <li>Collaborated with cross-functional teams to implement IT projects and initiative</li>
                                                        <li>Provided advanced technical support to end-users and resolved hardware and software issues</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Generic-Card-Orange'>
                                            <Accordion
                                                title={<h3>Cybersecurity Internship, Murray Security Services, 2018</h3>}
                                                content={
                                                    <ul>
                                                        <li>Worked with clients to deliver new password management infrastructure, strengthening overall security</li>
                                                        <li>Trained clients on cybersecurity best practices</li>
                                                        <li>Grew and studied under CEO and Founder, Dr. Shawn P. Murray</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Generic-Card-Orange'>
                                            <Accordion
                                                title={<h3>Independent Contractor, 2022</h3>}
                                                content={
                                                    <ul>
                                                        <li>Designed and installed new interior and exterior lighting system for small business (including smart switches) saving over $2000/Year</li>
                                                        <li>Strengthened building security for small businesses with new digital lock system including prox-cards and timed electronic locks</li>
                                                        <li>Installed new network appliances including high speed Cisco routers and Wireless Access Points</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Generic-Card-Orange'>
                                            <Accordion
                                                title={<h3>Audio/Visual Technician, Lewis Palmer School District, 2016-2021</h3>}
                                                content={
                                                    <ul>
                                                        <li>Executed the vision of the Director/Technical Director for over 40 live performances</li>
                                                        <li>Designed, installed, managed, documented, operated, and removed Sound and Lighting systems for live performances</li>
                                                        <li>Produced new long-term A/V solutions for conference/board rooms, studios, and performance halls across several buildings</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='Resume-section'>
                                    <h2>Class work</h2>
                                    <div className="Classwork-section">

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Networks</h3>} 
                                                content={
                                                    <p>
                                                        Study principles of communications on a network, including local area networks
                                                        and wide areay networks. Analyze communication protocols, TCP/IP, and the Internet.
                                                        Dive into Packet Switching as a network communication technique.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Cybersecurity</h3>} 
                                                content={
                                                    <p>
                                                        Examine contemporary cyber-security issues; study techniques, programs, tools and 
                                                        methods of contemporary cyber-attacks and cyber-defenses. Utilize contemporary tools
                                                        to both launch and defend against cyber-attacks from within a sandboxed environment.
                                                        Create tools to invoke cyber-attacks against an adversary in a sandboxed environment.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>
                                                
                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Computer Organization</h3>} 
                                                content={
                                                    <p>
                                                        Create and simulate state machines. Analyze CPU and memory models and circuits.
                                                        Code programs for the created state machine using Assembly and C language.
                                                        Proficient in boolean algebra and digital logic and systems.
                                                        Study data representation and computational arithmetic.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Software Engineering</h3>} 
                                                content={
                                                    <p>
                                                        Create and publish a web application in the context of a Scrum-based Agile development project.
                                                        Learn configuration management, project management, requirements engineering, and 
                                                        systematic testing techniques.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Networks & Cybersecurity</h3>} 
                                                content={
                                                    <p>
                                                        Create and analyze infrastructure for secure networks. Study networking concepts & principals.
                                                        Build and implement systems with security by design.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>The Mathematics of Information Security</h3>} 
                                                content={
                                                    <p>Analyze and write codes and ciphers. Code programs to implement the Chinese remainder theorem. 
                                                       Examine primality testing and methods for choosing large prime numbers for public key ciphers.
                                                       Design programs to implement the RSA algorithm, finite fields, discrete algorithms, AES encryption,
                                                       and Elliptic Curve Cryptography.
                                                   </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Operating Systems</h3>} 
                                                content={
                                                    <p>
                                                        Analyze systems of memory organization, I/O control, and process control. Implement 
                                                        systems for process management, coordination, and resource management.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion 
                                                title={<h3>Systems Security</h3>} 
                                                content={
                                                    <p>
                                                        Analyze computers and systems for security. Examine authentication, access control, 
                                                        malicious software, and software security. Design and implement systems for
                                                        PGP and Encryption. Use the Confidentiality, Integrity, Authentication triad 
                                                        and associated concepts to create secure systems.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>

                                        <div className="course">
                                            <Accordion
                                                title={<h3>Software Development with C++</h3>} 
                                                content={
                                                    <p>
                                                        Design and modify large software written in C++.
                                                        Relate programming language to its machine implementation using program analysis tools.
                                                    </p>
                                                } 
                                                color={"#fd6a00"} 
                                                bodycolor={"#fd6a00"}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='Experience-right'>

                                <div className='Resume-Section'>
                                    <div className='Qualifications'>
                                        <h2>Qualifications</h2>

                                        <div className='Qualification-card'>
                                            <Accordion
                                                title={<h3>Technical Skills</h3>}
                                                content={
                                                    <ul>
                                                        <li>Operating Systems: Linux (CentOS, RedHat, Debian, Arch, and variants), Windows, and macOS</li>
                                                        <li>Virtualization: VirtualBox, Hyper-V, XCP/Citrix</li>
                                                        <li>Scripting: Bash, Powershell, Python, Ansible</li>
                                                        <li>Networking: TCP/IP, DNS, DHCP/Static IP, VLAN, VPN</li>
                                                        <li>Server Applications: Apache, Nginx, MySQL/MariaDB, Docker</li>
                                                        <li>Monitoring/Metrics: rsyslog, Prometheus & Grafana</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Qualification-card'>
                                            <Accordion
                                                title={<h3>Programming Languages</h3>}
                                                content={
                                                    <ul>
                                                        <li>Highly Proficient in Powershell, Bash, Windows CMD, and command line</li>
                                                        <li>Java, MySQL</li>
                                                        <li>Javascript and React webapps</li>
                                                        <li>C & C++</li>
                                                        <li>C#</li>
                                                        <li>Python, Visual Basic & wscript/cscript.</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Qualification-card'>
                                            <Accordion
                                                title={<h3>Linux</h3>}
                                                content={
                                                    <ul>
                                                        <li>Red Hat Enterprise Linux (RHEL) and Linux System Administration</li>
                                                        <li>Installing, supporting, and maintaining Linux installations for both client and server machines</li>
                                                        <li>Experience across several distributions, including Arch, Debian, Fedora, CentOS, Rocky, and variants</li>
                                                        <li>Comfortable in a command-line only environment - highly comfortable with the command line</li>
                                                        <li>Writing configuration files for programs and services from scratch</li>
                                                        <li>Maintaining secure installations with Fail2Ban and strict remote access restrictions</li>
                                                        <li>Building firewalls with iptables and configuring Networking settings</li>
                                                        <li>Building, installing, and running Docker containerized services</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Qualification-card'>
                                            <Accordion
                                                title={<h3>Windows</h3>}
                                                content={
                                                    <ul>
                                                        <li>Highly proficient in the Windows Registry and Group Policy (including Security Policy)</li>
                                                        <li>Highly Proficient in Microsoft for Enterprise, including MDT, SCCM, and Windows Server Active Directory.</li>
                                                        <li>Can quickly build and deploy custom Windows images with tools like DISM and MDT</li>
                                                        <li>Experience Administering Windows Server, Hyper-V, Windows File Servers, Windows Deployment Services, Active Directory Domains, Windows Print Servers</li>
                                                        <li>Automate day-to-day tasks (and large complex tasks) with Powershell and CMD</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                        <div className='Qualification-card'>
                                            <Accordion
                                                title={<h3>Other Skills</h3>}
                                                content={
                                                    <ul>
                                                        <li>People-oriented</li>
                                                        <li>Consistent</li>
                                                        <li>Focused</li>
                                                        <li>Plan and Prioritize</li>
                                                        <li>Human-centric approach to problem resolution and innovation</li>
                                                        <li>Strong communication skills</li>
                                                        <li>Leadership, willing to train others</li>
                                                        <li>Growth mindset</li>
                                                    </ul>
                                                }
                                                color={'#fff'}
                                                bodycolor={'#fff'}
                                                defaultopen={true}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>

                <div className='Resume-Content-column-right'>

                    <div className='Resume-Section'>
                        <h2>Education and Certifications</h2>

                        <div className='Generic-Card-Orange'>
                            <Accordion
                                title={
                                    <div className='Education-Degree'>
                                        <h3>Bachelor of Science in Computer Science</h3>
                                        <h4>With a concentration in Networks and Cyber Security</h4>
                                        <h4>Minor: Mathematics</h4>
                                    </div>
                                }
                                content={
                                    <div className='Education-School'>
                                        <p>
                                            Colorado State University
                                            <br/>Fort Collins, CO
                                            <br/>August 2020 - May 2023
                                        </p>
                                        <img src={csu} alt="Colorado State University logo"/>
                                    </div>
                                }
                                color={'#fff'}
                                bodycolor={'#fff'}
                            />
                        </div>

                        <div className='Generic-Card-Orange'>
                            <Accordion
                                title={<h3>Security+</h3>}
                                content={
                                    <div className='Education-School'>
                                        <p>
                                            CompTIA
                                            <br/>May 2023
                                        </p>
                                        <img src={comptia} alt="comptia logo"/>
                                    </div>
                                }
                                color={'#fff'}
                                bodycolor={'#fff'}
                            />
                        </div>

                        <div className='Generic-Card-Orange'>
                            <Accordion
                                title={<h3>Certified Secure Computer User (C|SCU)</h3>}
                                content={
                                    <div className='Education-School'>
                                        <p>
                                            EC-Council
                                        </p>
                                        <img src={eccouncil} alt="comptia logo"/>
                                    </div>
                                }
                                color={'#fff'}
                                bodycolor={'#fff'}
                            />
                        </div>

                    </div>

                    <div className='Resume-Section'>
                        <hr/>

                        <div className='AAC'>
                            <h2 style={{textAlign:'center'}}>Awards and Accomplishments</h2>
                            <ul className='Awards-list'>
                                <li>Eagle Scout, 2016</li>
                                <li>CSU Student Employee of the Year Nominee, 2023</li>
                                <li>DANTE by Audinate, Level 1 Certified, 2018</li>
                                <li>AP Scholar with Distinction, 2020</li>
                                <li>Top 20 Informative Speakers in Colorado, 2018</li>
                            </ul>
                        </div>

                        <hr/>
                    </div>

                    <div className='Resume-Section'>
                        <h2>Projects</h2>
                        <div className='Generic-Card-Orange'>
                            <Accordion
                                title={<h3>Digital Signage</h3>}
                                content={
                                    <ul>
                                        <li>Implemented new Digial Signage solution</li>
                                        <li>Created and administered server infrastructure</li>
                                        <li>Redesigned deployment infrastructure for client computers</li>
                                    </ul>
                                }
                                color="#fff"
                                bodycolor={"#fff"}
                            />
                        </div>

                        <div className='Generic-Card-Orange'>
                            <Accordion
                                title={<h3>Push 3.0</h3>}
                                content={
                                    <ul>
                                        <li>Created an automatic solution for deploying software to a computer</li>
                                        <li>Written in Powershell</li>
                                        <li>Can be used to deploy one or more softwares to one or more computers</li>
                                    </ul>
                                }
                                color="#fff"
                                bodycolor={"#fff"}
                            />
                        </div>

                        <div className='Generic-Card-Orange'>
                            <Accordion
                                title={<h3>Microsoft Deployment Toolkit/SCCM</h3>}
                                content={
                                    <ul>
                                        <li>Implemented a new MDT Share for deploying Windows and applications in the organization</li>
                                        <li>Improved speed of deployments by over 5000%</li>
                                        <li>Built and designed custom Windows 10, 11, Server 2016, 2019, and 2022 images for deployment</li>
                                        <li>Wrote automatic/silent installers for over 200 applications</li>
                                    </ul>
                                }
                                color="#fff"
                                bodycolor={"#fff"}
                            />
                        </div>
                    </div>

                    <div className='Resume-Section'>
                        <h2>Keywords</h2>
                        <div className='Keywords-Section'>
                            <h3>Github</h3>
                            <h3>Business Development</h3>
                            <h3>File Sharing</h3>
                            <h3>Networking Infrastructure</h3>
                            <h3>IT Infrastructure</h3>
                            <h3>Intune</h3>
                            <h3>Storage</h3>
                            <h3>Ubuntu</h3>
                            <h3>Arch Linux</h3>
                            <h3>Debian</h3>
                            <h3>RedHat</h3>
                            <h3>Vmware</h3>
                            <h3>SharePoint</h3>
                            <h3>Firewall</h3>
                            <h3>Agile Development</h3>
                            <h3>Management at scale</h3>
                            <h3>Asset Management</h3>
                            <h3>Linux</h3>
                            <h3>System Administrator</h3>
                            <h3>Baked in Security</h3>
                            <h3>Mobile Device Management</h3>
                            <h3>Compliance</h3>
                            <h3>Design</h3>
                            <h3>Risk Management</h3>
                            <h3>Active Directory</h3>
                            <h3>Virtual Machines</h3>
                            <h3>99.9% reliability</h3>
                            <h3>SCCM</h3>
                            <h3>Automation</h3>
                            <h3>Documentation</h3>
                            <h3>Training</h3>
                            <h3>Azure</h3>
                            <h3>Microsoft Deployment Toolkit</h3>
                            <h3>Nginx</h3>
                            <h3>Postgres</h3>
                            <h3>SQL</h3>
                            <h3>Group Policy</h3>
                            <h3>Windows Server</h3>
                            <h3>Microsoft for Enterprise</h3>
                            <h3>Enterprise Linux</h3>
                            <h3>Consultant</h3>
                            <h3>Manager</h3>
                            <h3>Leader</h3>
                            <h3>Self-motivated</h3>
                            <h3>Dependable</h3>
                            <h3>Team-oriented</h3>
                            <h3>Attention to detail</h3>
                            <h3>Disaster Recovery</h3>
                            <h3>Cybersecurity</h3>
                            <h3>Malware Analysis</h3>
                            <h3>Advanced Threat Protection</h3>
                            <h3>Threat monitoring</h3>
                            <h3>Sandboxed environments</h3>
                            <h3>Open Source Software</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Resume