import './footer.css'

const Footer = () => {
    return (
        <footer>
            <p>
                Designed by Kyle Ketchell in Colorado
                <br />
                &#169; 2023 Kyle Ketchell
            </p>
        </footer>
    )
}

export default Footer