import './SideNav.css';

import winicon from './winicon.svg';
import linuxicon from './Arch-linux-logo-883010283.png';
import policyicon from './policy.svg';
import cliicon from './cli.svg';

import Link from '../Link';

const SideNav = () => {
    return (
        <div className="SideNav">
            <nav>
                <ul>
                    <li className='Icon'><Link href="/windows"><img src={winicon} alt="Windows Icon"/></Link></li>
                    <li className='Icon'><Link href="/linux"><img src={linuxicon} alt="Arch Linux Icon"/></Link></li>
                    <li className='Icon'><Link href="/policy-reference"><img src={policyicon} alt="Policy Icon"/></Link></li>
                    <li className='Icon'><Link href="/cli-reference"><img src={cliicon} alt="Command Line Interface Icon"/></Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default SideNav