import React from 'react';
import './linux.css'

const Linux = () => {
    return (
        <div className='Windows-page'>
            <h1>Linux System Administration</h1>
            <p>
                My daily driver: Arch Linux
                <br />
                My backup distro: Debian
                <br />
                My enterprise/stable distro: Debian or Rocky Linux
            </p>
            <h2>I have experience with:</h2>

            <div className="Content">
                <ul>
                    <li>Ubuntu, Linux Mint, Fedora, RedHat Enterprise Linux (CentOS), Debian, Arch Linux, Rocky Linux</li>
                    <li>Manually configuring Boot options with GRUB and systemd-boot</li>
                    <li>Encrypting filesystems with LUKS and cryptsetup</li>
                    <li>Managing filesystems with LVM</li>
                    <li>Creating and managing Virtual Machines with XCP-NG (the free version)</li>
                    <li>All of the Linux administration things</li>
                    <li>Managing users and groups, as well as permissions and such</li>
                    <li>Administering a SQL server (using MariaDB)</li>
                </ul>
            </div>
        </div>
    )
}

export default Linux