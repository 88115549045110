import "@fontsource/b612";
import "@fontsource/b612-mono";
import "./style.css";

import LTM from "./Resources/LabTechManualv1.docx";
import printicon from '../../resume/printer.svg';
import WindowsNav from "../WindowsNav";

const WAQRF = () => {
    return (
        <div className="LabTechManual">
            <WindowsNav />
            <h1>The Windows System Administrator's Quick Reference Fieldbook</h1>
            <h2>(Or, WAQRF, pronunced "Warf". The <a href="https://en.wikipedia.org/wiki/Q_(Star_Trek)">Q</a> is silent.)</h2>

            <a className='Contact-item' href={LTM} download="LabTechManualv1.docx">
                <img src={printicon} alt="Print version of this document"></img>
                <h3>Download print version</h3>
            </a>

            <h3>What is this?</h3>
            <p>
                The Windows System Administrator's Quick Reference Fieldbook 
                is my personal collection of tools, scripts, and more that can help you get 
                an entire IT infrastrucutre running from just a single Windows 10 install USB
                and a whole lot of hardware.
            </p>

        </div>
    )
}

export default WAQRF;