import React, { useState } from 'react';

import './accordion-style.css';

const Accordion = ({title, content, color, bodycolor, defaultopen}) => {
    const [isActive, setIsActive] = useState((() => {
        if (defaultopen === true) {
            return true
        } else {
            return false
        }
    }))

    return (
        <div className="accordion-item" key={title} style={{width:'100%',}}>
            <div className="accordion-title" onClick={() => setIsActive(!isActive)} style={{background:color,}}>
                <div style={{float:'left'}}>{title}</div>
                <div style={{float:'right'}}>{isActive ? '' : ''}</div>
            </div>
            {isActive && <div className="accordion-content" style={{background:bodycolor,}}>{content}</div>}
        </div>
    )
}

export default Accordion;