import './style.css';
import WindowsNav from '../WindowsNav';

import DAD from './DAD';
import FUD from './FUD';
import Automate from './Automate';
import OrganizingRules from './OrganizingRules';
import OfflineMedia from './CreateOfflineMedia';

const MDTIndex = () => {
    return (
        <div style={{width:"100%",}}>
            <WindowsNav />

            <h1>MDT Tips and tricks</h1>

            <a href="https://learn.microsoft.com/en-us/windows/deployment/deploy-windows-mdt/deploy-a-windows-10-image-using-mdt">https://learn.microsoft.com/en-us/windows/deployment/deploy-windows-mdt/deploy-a-windows-10-image-using-mdt</a>

            <p>Well, you heard the man.</p>

            <div className='MDTPage-Content'>
                <DAD />
                <FUD />
                <Automate />
                <OrganizingRules />
                <OfflineMedia />
            </div>
        </div>
    )
}

export default MDTIndex;