import './App.css';

import "@fontsource/open-sans";
import "@fontsource/roboto";


import React from 'react';
import Route from './components/Route';

import Header from './components/Header';
import SideNav from './components/SideNav';
import Footer from './components/Footer';

import Home from './pages/index';
import About from './pages/about';
import Windows from './pages/windows';
import Linux from './pages/linux';
import PolicyReference from './pages/PolicyReference';
import Resume from './pages/resume';
import Cliref from './pages/cliref';
import CS312 from './pages/cs312';
import WAQRF from './pages/windows/WAQRF';
import MDTIndex from './pages/windows/MDT';

function App() {
  return (
    <div>
      <Header />
      <div className="Page-body">
        <div className="Page-nav">
          <SideNav />
        </div>
        <div className='Page-content'>
          <Route path="/"><Home /></Route>
          <Route path="/about"><About /></Route>
          <Route path="/resume"><Resume /></Route>
          <Route path="/windows"><Windows /></Route>
          <Route path="/linux"><Linux /></Route>
          <Route path="/policy-reference"><PolicyReference /></Route>
          <Route path="/cli-reference"><Cliref /></Route>
          <Route path="/cs312"><CS312 /></Route>
          <Route path="/windows/WAQRF"><WAQRF /></Route>
          <Route path="/windows/MDT"><MDTIndex /></Route>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
