import './style.css';
import Accordion from "../../../components/Accordion";

const OfflineMedia = () => {
    return (
        <div className="Tip-division">
            <Accordion
                title={<h2>Put your share on an Offline Media to deploy without a network connection</h2>}
                content={
                    <p>
                        This isn't strictly necessary, but can come in handy in some scenarios.
                        <hr/>
                        <h3>Step 1: Create a selection profile that will grab only the things you need for this offline deployment</h3>
                        In MDT under "Advanced Configuration", choose "Selection Profiles", and create a new selection profile. Note - you don't <em>have</em> to create a selection profile.
                        However, in my case, my share is 3+TB of Apps, Operating Systems, and Drivers so when I create my offline media I set it up to only grab the specific things I'm intending to install.
                        <h3>Step 2: Create your offline media item</h3>
                        Under "Media", create a new Media. Provide a path to your media (like C:\offline_media) and choose your slimmed down selection profile.
                        <h3>Step 3: Set the properties for your offline media</h3>
                        Open your newly created media, and confirm your preferences (do you want to create both an x86 and x64 boot image?)
                        You'll need to copy over any of your rules (customsettings.ini) or bootstrap.ini preferences if necessary.
                        <h3>Step 4: Generate your offline media</h3>
                        Right click on the Media from the list of available media and choose "Update Media Content".
                    </p>
                }
                color={'#fff'}
                bodycolor={'#fff'}
            />
        </div>
    )
}

export default OfflineMedia;