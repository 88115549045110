import { FunFinePrint, FunnerFinePrint } from "../../components/Fineprint"
import './about.css';
import beartruth from './beartruth.png';
import ski from './ski.jpg';

const About = () => {
    return (
        <div className="about-content">
            <h1>About Me</h1>

            <div className="Philosophy-div">
                <p className="Philosophy">
                    Doug Gwyn once famously said, "Unix was not designed to stop you from doing stupid things, 
                    because that would also stop you from doing clever things."
                    <br/>
                    I firmly believe in this philosophy, that systems designed to prevent misuse are actually 
                    useless and hamper creativity and innovation. Brilliant system design doesn't mean an excess 
                    of safeguards to prevent accidents or intentional misuse, but rather brilliant systems are 
                    those that indulge and encourage the free flow of ideas and creativity from which stem the most 
                    beautiful expressions of humanity in acts of creation and innovation. Set free from the constraints
                    of a system the human mind is among the most powerful living things in the universe. The greatest
                    sin lies not in the failure of a man but in the most malicious act of enslaving him, starving
                    him in his desire for love, creation, and self expression.
                </p>        
            </div>

            <div className="journalism">
                <div className="j-words">
                    <p>
                        <span className="Hi-dropcap">Hi.</span>I'm Kyle Ketchell. 
                        I grew up in the small town of Monument, Colorado. While attending Palmer Ridge High School I discovered a love for Drop Caps and Fonts 
                        (and also writing) as the Assistant and later acting Editor in Chief of the <a href="https://prhsbeartruth.org/">Bear Truth Newspaper</a>, Palmer Ridge's nationally
                        recognized school newspaper and newsmagazine.
                    </p>
                </div>
                <img src={beartruth} className="j-photo" alt="PRHS Bear Truth logo"/>
            </div>

            <p>
                As a trumpet player and all-around musician I've had the uniquie privelage to perform with the 
                nationally recognized Palmer Ridge High School Wind Symphony on stages including 
                Clowes Memorial Hall at the Butler Arts Center in Indianapolis, Indiana,
                as well as other smaller stages with groups like the Tri-Lakes Music Association and the Jazz on the Ridge
                ensemble. I've independently produced and recorded several of my own albums and singles, including "Space age" (2020, album)
                and "Why do you always do that thing you do?" (2022, single).
            </p>

            <p>
                I began my working career at the young age of 15 with Lewis Palmer School District as an A/V Technician 
                designing and operating Audio Visual systems for conference rooms, board rooms, 
                studios, and performance halls for a wide variety of groups. I've worked with large 
                multi-national organizations, theatre groups, musical artists, and more. I've also had the distinct honor 
                of working with both nationally and internationally recognized ballet performers and dance companies. All told
                I've worked on over 40 shows, including over 500 rehersals and 90 live performances.
            </p>

            <div className="College-life">
                <img src={ski} alt="ski" className="College-life-img" />
                <p className="College-life-text">
                    In August of 2020 I moved to Fort Collins, CO to pursue a degree in 
                    Computer Science from Colorado State University. While at CSU I began 
                    working with Engineering Technology Services, providing IT support for CSU's 
                    Walter Scott, Jr. College of Engineering. As a Lab Technician and System Administrator
                    with ETS I rebuilt the Deployment Infrastructure for the ETS Labs team, designing custom
                    Windows Server 2019, 2022 and Windows 10 images for deployment to the College of Engineering
                    lab spaces. I also wrote over 200 silent installers complete with documentation for posterity.
                <br/>
                <br/>
                    While living in Fort Collins I had the unique opportunity to learn to Ski at some of the coolest
                    ski resorts in Colorado, including Keystone, Breckenridge, Vail, and Winter Park. 
                </p>
            </div>

            <p>
                I love to find ways to make things better. "How can this be easier?" "Can we make this faster?"
                "This is such a hassle, it should be less difficult." These questions define the work that I do
                and the answer ends up making things better and easier for everyone around me. As an A/V tech that
                looked like better labling and organization systems in storage closets so that it became easier
                and faster to find and put away equipment. As a System Administrator this meant rethinking how 
                we deployed computers, a new computer can now be set up and ready to go in under 20 minutes 
                instead of 8 hours.
            </p>

            <p>
                I am an avid tech nerd, gamer, and outdoorsman. I built my first PC when I was just 15 from a few parts that 
                just barely fit together. This first step into the world of computers grew into a love of all things technology.
                I've had years of experience administering both Windows and Linux systems (read: I've fought hard with a whole
                lot of computers).
                In my free time I enjoy building and tweaking computers, drooling over the latest and greatest in PC hardware, and writing code.
                I love working with computers and solving complex computer problems.
            </p>

            <p>
                Whether or not Doug Gwyn was a real person is a question best left to scholars and those who knew him best. But
                the philosophy is sound. I firmly believe in and actively strive to design systems that encourage innovation,
                creation, production, and genesis in all its forms. Brilliant innovation can never exist in an environment starved
                of any freedom. But the end result of brilliant innovation is always freedom in its most pure form:
                the human mind acting to express himself in and with and through the universe in which he lives.
            </p>


            <hr/>

            <FunFinePrint />
            <FunnerFinePrint />
        </div>
    )
}

export default About