import './style.css';
import Accordion from "../../../components/Accordion";

const Automate = () => {
    return (
        <div className="Tip-division">
            <Accordion
                title={<h2>Hide pages I don't need to see</h2>}
                content={
                    <p>
                        By default, MDT Task Sequences offer a whole lot of customization options during the start up process. But when I'm deploying 150 machines with my 
                        special just-how-I-like-it OS, Apps, and customized Task Sequence, I don't want MDT to ask me 19 questions before letting me click "go". You can do
                        that pretty easily by specifying some rules in the CustomSettings.ini and Bootstrap.ini files. 
                        <br/>
                        <h3>Edit the CustomSettings.ini file (the Rules)</h3>
                        In Deployment Workbench, right click on your MDT share, then select "Properties". Navigate to the "Rules" tab 
                        (see the "Organizing your Rules" tip for info about the Rules/CustomSettings.ini/Bootstrap.ini files).
                        To skip a screen during the setup process, simply say "SkipScreenTitle=YES". The default option is to not skip the screen, 
                        to not skip it (to show the screen during the setup process) you can either explicitly specify "SkipScreenTitle=NO" or just don't specify it at all.
                        <h4>Note - you might not want to configure all of the following rules, but pick&choose which ones you want.</h4>
                        <ul>
                            <li>SkipCapture=NO</li>
                            <li>SkipAdminPassword=YES</li>
                            <li>AdminPassword=deploypassword</li>
                            <li>SkipDeploymentType=YES</li>
                            <li>SkipComputerName=NO</li>
                            <li>SkipDestinationDisk=YES</li>
                            <li>SkipDomainMembership=YES</li>
                            <li>SkipProductKey=YES</li>
                            <li>SkipComputerBackup=YES</li>
                            <li>SkipBitLocker=YES</li>
                            <li>SkipLocaleSelection=YES</li>
                            <li>SkipFinalSummary=YES</li>
                            <li>SkipPackageDisplay=YES</li>
                            <li>SkipSummary=YES</li>
                            <li>SkipApplications=YES</li>
                            <li>SkipAppsOnUpgrade=YES</li>
                            <li>SkipTimezone=YES</li>
                            <li>SkipUserData=YES</li>
                        </ul>
                        If you choose to skip the admin password screen, you also need to specify the AdminPassword variable.
                        <h3>Edit the Bootstrap.ini file</h3>
                        Some of the screens (specifically BDDWelcome) need to be skipped vai the Bootstrap.ini file. Still on the Rules tab of the Properties window,
                        click the "Edit Bootstrap.ini" button.
                        <ul>
                            <li>
                                SkipBDDWelcome=YES
                            </li>
                        </ul>
                    </p>
                }
                color={'#fff'}
                bodycolor={'#fff'}
            />
        </div>
    )
}

export default Automate;