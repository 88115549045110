
const Cliref = () => {
    return (
        <div>
            <h1>K-Kternals</h1>
            <p>
                I love (some of the tools on) Windows Sysinternals.
                <br/>
                I love them so much so that I wrote my own set of tools which I called kternals (get it?)
                <br/>
                You can find kternals on my Github: <a href="https://github.com/k-katfish/k-kternals">k-kternals</a>.
            </p>

            <h2>6th Sense</h2>
            <p>
                I have a love-hate relationship with Windows logging. On the bright side, Windows logs absolutely <em>everything</em> that happens in your system. On the downside... that's a whole lot of logs to look through.
                <br/>
                Fortunately though, that means that if you know what you're looking for, there is an event that gets logged for it!
                <br/>
                Microsoft Remote Desktop has a protocol called "shadow" which allows an administrator to view or even control a users's session with (or without) that user's consent. And you might have no idea it's happening.
                <br/>
                So I wrote a few schedueld tasks and documented them as a part of kternals. Simply download the task.xml files and import them into task scheduler. Run them as your user account and run only when you're logged in.
            </p>

            <h2>AutoLogoff</h2>
            <p>
                This is the tool that really got all this started. This was super helpful to me as a lab administrator. A tool designed to automatically log a user off after a certain ammount of inactivity (after they lock the workstation). Meant to be deployed in a Server Active Directory domain environment. Put a copy of the .exe on a globally accessible share (like your domain controller's public share) and then create a GPO as described in the readme.
            </p>

            <h2>Scan</h2>
            <p>
                Scan is a tool that can scan a remote computer (running Microsoft Windows and joined to your Domain) and will return a lovely box of information about that computer, including CPU name, speed, cores, RAM size, Serial #, UUID, MAC Address, Disk partition sizes, and more. Super helpful for a helpdesk trying to figure out how old a computer is.
            </p>

            <h2>Thin Client</h2>
            <p>
                Thin clients are an excellent way to cost-effectively deploy a lab space. Spin up a few servers that will do all of your processing, then set up tiny cheap-butt Intel Nucs and use those as the desktop clients that people can see. Thin Client as a tool is designed to help facilitate this using Windows client and Windows Server operating systems. As soon as someone logs into a Windows "Thin Client" they are automatically connected to a remote Windows Server host using RDP. And when that remote session ends, the user is logged out of the local Thin Client.
            </p>
        </div>
    )
}

export default Cliref