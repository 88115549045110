import './style.css';
import Accordion from "../../../components/Accordion";
import CSPRODNAME from './Resources/wmicscproductgetname.png';

const DAD = () => {
    return (
        <div className="Tip-division">
            <Accordion
                title={<h2>Dynamically Apply Drivers</h2>}
                content={
                    <p>
                        Dynamically applying drivers with MDT is Easy! (sort of).<br/>
                        You can do it with just a few clicks! (kind of).<br/>
                        Ok it's not that bad <em>trust me bro</em>. You just have to be deliberate and you'll get it done. 
                        The following steps will assume your target computer is an HP computer, but this will work for any model of computer 
                        (however, not all manufacturers are as easy to work with as HP).
                        <h3>Step 1: Identify the model of your target computer.</h3>
                        Run the following command on the target computer: <code>wmic csproduct get name</code>
                        This will output the model of your target computer. On my target computer, the name was "HP Z2 Tower G5 Workstation".
                        <img src={CSPRODNAME} alt="output of the command wmic csproduct get name"></img>
                        <h3>Step 2: Grab your drivers from the internet</h3>
                        Depending on the make/model of your PC, it may be more or less difficult to find the drivers you need. However, HP makes it super easy: <a href="https://ftp.ext.hp.com/pub/caps-softpaq/cmit/HP_Driverpack_Matrix_x64.html">HP Client Management Solutions Driver Packs organized by OS and Model</a>.
                        Move around in the giant matrix of models and windows versions until you find your target spec, and download that .exe.
                        Use 7-zip to extract the contents of that exe to a folder, which you'll copy over to MDT in the next step.
                        <h3>Step 3: Create a driver folder for your target PC</h3>
                        In MDT, navigate to the Out-of-Box Drivers section. Right click &#8594; Create new folder. 
                        For the name, provide exactly the name that you got from the output of <code>wmic csproduct get name</code>.
                        <h3>Step 4: Import the drivers for your computer model.</h3>
                        Right click on your newly created Out-of-Box Drivers folder with the appropriate name, and choose "Import Drivers".
                        Browse to the folder of extracted drivers you grabbed earlier and import all of the drivers in that folder.
                        <h3>Step 5: Create a "Nothing" selection profile</h3>
                        If you don't have one already, create a new selection profile (Advanced Configuration &#8594; Selection Profiles). This should select nothing, and call it "nothing" so you know it doesn't select anything.
                        <h3>Step 5: Modify your task sequence to dynamically apply only the matching drivers from your model</h3>
                        Open your task sequence, and expand the Preinstall section. Select the "Inject Drivers" step, and change the selection profile to "Nothing". Choose the "Install all drivers from the selection profile" radio button.
                        Now, add a step to your task sequence, a "Set Task Sequence Variable" step. Put it somewhere before the "Inject Drivers" step in preinstall. You'll need to set the variable <code>DriverGroup001</code> to the value <code>%Model%</code> .
                        This will tell MDT to grab the drivers from <code>Out-of-box Drivers\Model-name folder</code>. If you put this in a separate sub-folder (like Oob Drivers\Windows 10 Drivers\HP Z2 Tower G5 Workstation), add that info to the path in your DriverGroup001 variable: <code>Windows 10 Drivers\%Model%</code>.
                        Alternatively, you could configure this as a rule for your deployment share (I do on my MDT servers). Add a line to your rules file: <code>DriverGroup001=%Model%</code> .
                    </p>
                }
                color={'#fff'}
                bodycolor={'#fff'}
            />
        </div>
    )
}

export default DAD;