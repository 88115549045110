import './pr.css';
import '../../components/Fineprint'
import Fineprint, { FunnerFinePrint } from '../../components/Fineprint';

import PolicyReport from './policies/GPResult';

const Disclaimer = () => {
    return (
        <div className="Disclaimer-box">
            <h3>Warning</h3>
            <p>You should not do anything you find on the internet without at least some understanding the implications of what you're doing.</p>
            <h4>Disclaimer:</h4>
            <p className="Disclaimer-text">Modifying Group Policy, Security Policy, or Registry settings could cause serious problems in your system if you screw it up. This could be a simple inconvience, or it could render your system unusable, requiring that you reinstall your operating system.<br /><strong>Modify these settings at your own risk.</strong></p>
        </div>
    )
}

const PolicyReference = () => {
    return (
        <div>
            <h1>Registry and Group Policy Quick References</h1>
            <p>These are mostly here for me, but maybe they'll help you too.</p>
            <Disclaimer />
            <p>Note: This information is provided in the "Domain Group Policy object" format, with the path being Computer/User Configuration &#8594; Policies &#8594; Software/Windows/Administrative. If you want to make these changes in local group policy, ignore the "Policies" step. To set the Windows settings things locally, do those with the apropriate tools.</p>

            <PolicyReport />

            <Fineprint />
            <FunnerFinePrint />
        </div>
    )
}

export default PolicyReference