import './style.css';
import Accordion from "../../../components/Accordion";
import CustomSettings from "./Resources/customsettingsini.png";

const OrganizingRules = () => {
    return (
        <div className="Tip-division">
            <Accordion
                title={<h2>Organize your Rules</h2>}
                content={
                    <p>
                        The CustomSettings.ini (Rules) file and Bootstrap.ini files are very much the same, though Bootstrap.ini gets read and set up immediately when LiteTouch.vbs gets launched,
                        CustomSettings.ini gets applied slightly later. This means that some of your behavior <em>needs</em> to be specified in your Bootstrap.ini file
                        (specifically the user account that can access the MDT share, and the SkipBDDWelcome rules <em>if</em> you choose to set these rules.)
                        <h3>General file structure</h3>
                        Each of these files starts with the [Settings] section. From there, you'll typically specify the next sections to process using the Priority= setting. 
                        You can list a bunch of things after Priority= as you create more sections. Check out this example:
                        <br/>
                        <img src={CustomSettings} alt="CustomSettings.ini file with several sections of rules." />
                        You can also call a section from within another section - check out the Microsoft website (link above) for more details.
                    </p>
                }
                color={'#fff'}
                bodycolor={'#fff'}
            />
        </div>
    )
}

export default OrganizingRules;