import Link from '../../../components/Link';
import './style.css';

const WindowsNav = () => {
    return (
        <div className='TopicNav'>
            <nav>
                <ul>
                    <li></li>
                    <li><Link href="/windows/MDT">MDT</Link></li>
                    <li><Link href="/windows/WAQRF">Quick Reference</Link></li>
                    <li></li>
                </ul>
            </nav>
        </div>
    )
}

export default WindowsNav;