import './fineprint.css'

export const FunnerFinePrint = () => {
    return (
        <p className="Funnerfineprint">
            Any product or information listed on this site, its parent site, any sub-site,
            or any product or information provided by Kyle Ketchell or affiliates is provided "AS IS", without any warranty of any kind, express or implied. 
            <br/>IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES, OR OTHER
            LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
            OUT OF OR IN CONNECTION WITH THIS INFORMATION OR THE USE OR OTHER DEALINGS IN
            THIS INFORMATION.
            <br />Kyle Ketchell or affiliates are not liable for damages arising from use or misuse,
            accident, lightning, flood, tornado, tsunami, volcanic eruption, earthquake, hurricanes and other Acts of God, 
            neglect, damage from improper reading or usage, incorrect line voltage, improper or unauthorized reading or usage, failure to follow instructions,
            broken antenna or marred cabinet, missing or altered serial numbers or other identification, or any missing or altered components,
            sonic boom vibrations, sub-sonic vibrations, customer adjustments of any kind,
            incidents owing to an airplane crash, ship sinking or taking on water, motor vehicle crashing, 
            dropping the item, falling rocks, leaky roof, broken glass, mud slides, forest fire, 
            or projectile (which can include, but not be limited to, arrows, bullets, shot, BB's, shrapnel, lasers, napalm, torpedoes, 
            or emissions of X-rays, Alpha, Beta and Gamma rays, knives, stones, etc.), 
            electromagnetic radiation from solar activity, electromagnetic radiation from sub-terranian activity, electromagnetic radiation from nuclear blasts,
            figher combat, gurrilla engagement, desert warfare, sea warfare, air-to-ground actions, air-to-sea actions, surface-to-air actions, 
            theaterwide tactical warfare, theaterwide biotoxic and chemical warfare, global thermonuclear war, or any other warlike actions at any time whether at war or at peace,
            unless otherwise specified. 
            <br />Other restrictions may apply. Terms are subject to change without notice. Do not remove this disclaimer under penalty of law. Void where prohibited. Beware of Dog. 
        </p>
    )
}

export const FunFinePrint = () => {
    return (
        <div>
            <p className="Funfineprint">
                This website is best experienced with Internet Explorer 3.4 or below on a Celeron with integrated graphics 
                emulated in CSS on a Commodore 64 at a screen resoultion of 832x17.
                <br/>Please enable dark mode and remove your device from Boat Mode and set it to Submarine Mode. 
                <br/>For security reasons, please leave num lock off while browsing and disable safe search.
            </p>
        </div>
    )
}


const Fineprint = () => {
    return (
        <p className="Fineprint">
            THIS INFORMATION IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
            IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
            FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
            AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
            LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
            OUT OF OR IN CONNECTION WITH THIS INFORMATION OR THE USE OR OTHER DEALINGS IN
            THIS INFORMATION.
        </p>
    )
}

export default Fineprint;