import Accordion from "../../../components/Accordion";

import './style.css';

import Data from './policies.json';

const DetailHeader = () => {
    return (
        <div className="Policy-Rule-Heading">
            <h4>Policy</h4>
            <h4>Setting</h4>
            <h4>Explain</h4>
        </div>
    )
}

const DetailDetail = ({policy, setting, explain}) => {
    return (
        <div className="Policy-Rule-Item">
            <p>{policy}</p>
            <p>{setting}</p>
            <p>{explain}</p>
        </div>
    )
}

const PolicyData = ({item}) => {
    return (
        <div style={{paddingLeft:'3px', marginLeft:'0px',}}>
            <div className="Policy-Rule">
                <DetailHeader />
                <hr className="LineBreak"/>
                {item.map(
                    (value) => {
                        return (
                            <div style={{paddingBottom:'5px',}}>
                                <DetailDetail policy={value.policy} setting={value.setting} explain={value.explain} />
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}

const ItemData = ({item, level}) => {
    const color = (() => {
        if (level === 0) {
            return ("#fef7d6")
        } else if (level === 1) {
            return ("#7197b3")
        } else if (level === 2) {
            return ("#a0bacb")
        } else if (level === 3) {
            return ("#c0d2de")
        }else if (level === 4) {
            return ("#d9e3ea")
        } else if (level === 5) {
            return ("#e8e8e8")
        }
    })();

    const PolicyInfo = (() => {
        if (item.hasOwnProperty("policies")) {
            return (
                <PolicyData item={item.policies}/>
            )
        }
    })

    const ChildData = (() => {
        if (item.hasOwnProperty("children")) {
            const childrendata = item.children.map(
                (child) => {
                    return (
                        <ItemData item={child} level={level + 1} />
                    )
                }
            )
            return ( childrendata )
        } else {
            return (<></>)
        }
    })

    return (
        <Accordion
            title={item.name}
            content={
                <div>
                    {ChildData()}
                    {PolicyInfo()}
                </div>
            }
            color={color}
        />
    )
}

const PolicyReport = () => {
    const DisplayData = Data.map(
        (item) => {
            /*if (item.hasOwnProperty("children")) {
                return (
                    <ParentItem name={item.name} children={item.children} level={0}/>
                )
            } else {
                return (
                    <ChildItem item={item}/>
                )
            }*/
            return (
                <ItemData item={item} level={0}/>
            )
        }
    )
    return (
        <div>{DisplayData}</div>
    )
}

export default PolicyReport;