import kkvideo from './kk-cs312.mp4';

const CS312 = () => {
    return (
        <div>
            <video controls>
                <source src={kkvideo} type="video/mp4"/>
            </video>

            <h1>Kyle Ketchell - CS312</h1>

            <p>
                This video made as a project for CS 312 at Colorado State University.
                <br/>
                Products 100% for sale. Please disable high-heat drying and enable safe search. For security please leave Numlock on while browsing.
            </p>
        </div>
    )
}

export default CS312

